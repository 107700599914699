var autofill = {
  timer: false,
  loading: false,
  search: '',
  suggestions: [],
  init: function() {
    $('[data-autofill]').on('keyup', function(e) {
      window.clearTimeout(autofill.timer);
      var what=$(this).val();
      if ( what.length >= 3 ) {
        var trigger = $(this);
        var where = trigger.attr('data-autofill').split('.');
        autofill.timer = window.setTimeout( function(e) {
          autofill.get( where[0], where[1], what, trigger );
        }, 1000 );
      }
    });
  },
  get: function( table, col, search, trigger ) {
    if ( autofill.loading === false ) {
      if ( autofill.search !== search.substring(0, 3) ) {
        autofill.search = search.substring(0, 3);
        autofill.loading = true;
        var ajaxurl = basepath +'ajax.php'
        var ajaxdata = {  'ajaxFunction': 'autofill',
                          'table': table,
                          'col': col,
                          'search': search.substring(0, 3) };
        $.ajax({
          url: ajaxurl,
          method: 'POST',
          dataType: 'json',
          data: ajaxdata,
          success: function(data) {
            autofill.suggestions = data;
            autofill.render( trigger, search );
            autofill.loading = false;
            return true;
          },
          error: function(e) {
            console.log('error');
            autofill.loading = false;
            return false;
          }
        });
      } else {
        autofill.render( trigger, search );
      }
    }
  },
  render: function(trigger, search) {
    if ( trigger.parent('.position-relative').length === 0 ) {
      trigger.wrap('<div class="position-relative">');
    }
    var html =  "<div class=\"autofill-container\">\n"+
                "  <ul class=\"autofill-suggestions\">\n";
    autofill.suggestions.forEach( suggestion => {
      if ( suggestion.name.toLowerCase().indexOf( search.toLowerCase() ) >= 0 ) {
        html += "    <li class=\"autofill-suggestion\">"+suggestion.name+"</li>\n";
      }
    });
    html += "  </ul>\n"+
            "</div>\n";
    if ( $('.autofill-container').length > 0 ) {
      $('.autofill-container').replaceWith(html);
    } else {
      $(html).insertAfter(trigger);
    }
    $('.autofill-suggestion').unbind().on('click', function(e) {
      trigger.val($(this).html());
      $('.autofill-container').remove();
    })
  }
}
