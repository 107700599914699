var measurements = {
  init: function() {
  },

  getChartdata: function(project, startdate, enddate, nutrition) {
    if ( site.isLoading() === false ) {
      site.isLoading(true);
      var ajaxurl = site.link( ['dashboard'], { 'show': [ project, nutrition, startdate, enddate ], 'ajax': true } );
      $.ajax({
        url: ajaxurl,
        method: 'GET',
        dataType: 'json',
        success: function(data) {
          site.isLoading(false);
          return chartHelper.updateChart(data);
          
        },
        error: function(e) {
          console.log('error');
          site.isLoading(false);
          return false;
        }
      });
    }
  },

  emptyForm: function() {
    
  }
}
