var CryptoJS = CryptoJS || {};
var site = site || {};
var user = user || {};
var autofill = autofill || {};
var measurements = measurements || {};
var admin = admin || {};
var luxon = luxon || {};
var Chart = Chart || {};
var gt = window.i18n();
function _( input ) {
  return gt.gettext(input);
};

CanvasRenderingContext2D.prototype.roundRect = function (x, y, width, height, radius) {
  if (width < 2 * radius) radius = width / 2;
  if (height < 2 * radius) radius = height / 2;
  this.beginPath();
  this.moveTo(x + radius, y);
  this.arcTo(x + width, y, x + width, y + height, radius);
  this.arcTo(x + width, y + height, x, y + height, radius);
  this.arcTo(x, y + height, x, y, radius);
  this.arcTo(x, y, x + width, y, radius);
  this.closePath();
  return this;
};

$( document ).ready(function() {
  'use strict'
  site.init();
  var chartHelper = chartHelper || {};
});
