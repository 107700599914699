var chartHelper = {
  data: {
    'labels': [luxon.DateTime.now().toJSDate()],
    'datasets': [{
      'label': 'x',
      'data': [0],
      'fill': false,
      'borderColor': 'rgb(75, 192, 192)',
      'tension': 0.1
    }]
  },
  config: {
    type: 'line',
    data: false,
    options: {
      plugins: {
        filler: {
          propagate: false,
        },
        legend: {
          display: true,
          position: 'bottom',
          labels: {
            boxHeight: 30,
            boxWidth: 50,
            font: {
              size: 18,
            },
            generateLabels: function() {
              var labels = [
                {
                  text: _('Optimal range'),
                  fillStyle: 'rgba(75,192,75,.5)',
                  strokeStyle: '#4bc04b',
                },
                {
                  text: _('Nutrition'),
                  fillStyle: '#e6e6e6',
                  strokeStyle: '#4bc0c0',
                },
                {
                  text: _('New BBCH stage'),
                  borderRadius: 5,
                  fillStyle: '#266868',
                  strokeStyle: '#266868',
                }
              ];
              return labels;
            }
          },
        },
        title: {
          display: true,
          text: 'Project name',
          font: {
            size: 24,
            weight: 'bold',
          },
          padding: {
            top: 0,
            left: 0,
            right: 0,
            bottom: 10,
          }
        },
        subtitle: {
          display: true,
          text: 'timespan',
          font: {
            size: 16,
          },
          padding: {
            top: 0,
            left: 0,
            right: 0,
            bottom: 20,
          }
        },
        tooltip: {
          enabled: false,
          position: 'nearest',
          titleFont: {
            weight: 'bold',
            size: 24,
          },
          bodyFont: {
            size: 18,
          },
          padding: 15,
          external: this.renderTooltip,
          callbacks: {
            label: function( items ) {
              var unit = 'ppm';
              if ( items.dataset.label.toLowerCase() == 'ph' ) {
                unit='';
              }
              if ( ( items.dataset.label == 'advice_min' ) || ( items.dataset.label == 'advice_max' ) ) {
                return '';
              }
              return items.dataset.label +': '+ items.formattedValue +' '+ unit;
            },
            labelColor: function( items ) {
              var bgColor = 'rgb(240,240,240)';
              if ( items.dataset.label == 'NO3' ) {
                bgColor = 'rgb(75, 192, 192)';
              } else if ( ( items.dataset.label == 'advice_min' ) || ( items.dataset.label == 'advice_max' ) ) {
                bgColor = 'rgb(75, 192, 75)';
              } else {
                bgColor = 'rgb(192, 192, 192)';
              }
              return { backgroundColor: bgColor };
            },
          },
        },
      },
      parsing: {
        xAxisKey: 'date',
        yAxisKey: 'value',
      },
      scales: {
        y: {
          type: 'linear',
          position: 'left',
          min: 0,
          title: {
            text: 'ppm',
            display: true,
          },
        },
        x: {
          type: 'time',
          time: {
            unit: 'day',
            tooltipFormat: 'yyyy-LL-dd',
            displayFormats: {
              day: 'yyyy-LL-dd',
            },
          },
          ticks: {
            source: 'auto',
            autoSkip: true,
          }
        },
      },
      interaction: {
        mode: 'index',
        intersect: false
      },
      layout: {
        padding: {
          top: 20,
          bottom: 20,
          left: 20,
          right: 20,
        },
      },
      pointBackgroundColor: '#fff',
      radius: 10,
      hoverRadius: 20,
      onResize: function( c, size ) {
        if ( size.width < 600 ) {
          if ( c.aspectRatio > 1 ) {
            c.config.options.layout.padding.top=0;
            c.config.options.layout.padding.right=0;
            c.config.options.layout.padding.bottom=10;
            c.config.options.layout.padding.left=0;
            c.config.options.aspectRatio=1;
            c.resize();
            c.update();
          }
        } else {
          if ( c.aspectRatio < 2 ) {
            c.config.options.aspectRatio=2;
            c.config.options.layout.padding.top=50;
            c.config.options.layout.padding.right=20;
            c.config.options.layout.padding.bottom=20;
            c.config.options.layout.padding.left=20;
            c.resize();
            c.update();
          }
        }
      },
      onHover: function(e) {
        e.chart.getDatasetMeta(0).data.forEach( function(item) {
          if ( item.active === true ) {
            if ( typeof item.options.pointStyle === 'object' ) {
              item.options.pointStyle='rect';
            }
          } else {
            if ( item.options.pointStyle === 'rect' ) {
              item.options.pointStyle=document.getElementById('bbch'+item.$context.raw.bbch);
            }
          }
        });
      }
    },
  },
  dashboardChart: false,
  ctx: false,
  init: function() {
    this.ctx = document.getElementById('dashboardChart').getContext('2d');
    this.config.data = this.data;
    this.dashboardChart = new Chart(this.ctx, this.config);
  },
  updateChart: function(inputdata) {
    this.config.data = inputdata.chartdata;
    var prev=0;
    var a = [];
    var s = [];
    var h = [];
    var c = [];
    var b = [];
    this.dashboardChart.data.datasets[0].data.forEach( d => { 
      if ( d.bbch !== prev ) {
        prev = d.bbch;
        a.push( this.renderBBCHPoint(d.bbch) )
        s.push( 15 );
        h.push( 25 );
        c.push( 'rgb(38, 104, 104)' );
        b.push( 'rgb(38, 104, 104)' );
      } else {
        a.push( 'circle' );
        s.push( 10 );
        h.push( 20 );
        c.push( 'rgb(230, 230, 230)' );
        b.push( 'rgb(75, 192, 192)' );
      }
    });
    for ( let i=0; i<this.dashboardChart.data.labels.length; i++ ) {
      this.dashboardChart.data.labels[i] = luxon.DateTime.fromSeconds(this.dashboardChart.data.labels[i]);
    }
    for ( let i=0; i<this.dashboardChart.data.datasets[0].data.length; i++ ) {
      this.dashboardChart.data.datasets[0].data[i].date = luxon.DateTime.fromSeconds(this.dashboardChart.data.datasets[0].data[i].date);
    }
    
    this.dashboardChart.tooltip.options.external=this.renderTooltip;
    this.dashboardChart.data.datasets[0].pointStyle=a;
    this.dashboardChart.data.datasets[0].pointRadius=s;
    this.dashboardChart.data.datasets[0].pointHoverRadius=h;
    this.dashboardChart.data.datasets[0].pointHitRadius=0;
    this.dashboardChart.data.datasets[0].pointBorderColor=b;
    this.dashboardChart.data.datasets[0].pointBackgroundColor=c;
    this.dashboardChart.config.options.plugins.title.text=inputdata.projectname;
    this.dashboardChart.config.options.plugins.subtitle.text=luxon.DateTime.fromSeconds(parseInt(inputdata.startdate)).toISODate() +' - '+ luxon.DateTime.fromSeconds(parseInt(inputdata.enddate)).toISODate();
    this.dashboardChart.update();
    this.dashboardChart.legend.legendItems[1].text=inputdata.nutritionname.name;
    this.dashboardChart.render();
    return true;
  },
  getOrCreateTooltip: function (chart) {
    let tooltipEl = chart.canvas.parentNode.querySelector('div');
  
    if (!tooltipEl) {
      tooltipEl = document.createElement('div');
      tooltipEl.style.background = 'rgba(0, 0, 0, 0.7)';
      tooltipEl.style.borderRadius = '3px';
      tooltipEl.style.color = 'white';
      tooltipEl.style.opacity = 1;
      tooltipEl.style.pointerEvents = 'none';
      tooltipEl.style.position = 'absolute';
      tooltipEl.style.transform = 'translate(-50%, 0)';
      tooltipEl.style.transition = 'all .1s ease';
  
      const table = document.createElement('table');
      table.style.margin = '0px';
  
      tooltipEl.appendChild(table);
      chart.canvas.parentNode.appendChild(tooltipEl);
    }
  
    return tooltipEl;
  },
  renderTooltip: function( ctx ) {
    const {chart, tooltip} = ctx;
    const tooltipEl = chartHelper.getOrCreateTooltip(chart);
    if (tooltip.opacity === 0) {
      tooltipEl.style.opacity = 0;
      return;
    }
    if (tooltip.body) {
      const titleLines = tooltip.title || [];
      const bodyLines = tooltip.body.map(b => b.lines);
  
      const tableHead = document.createElement('thead');
  
      titleLines.forEach(title => {
        const tr = document.createElement('tr');
        tr.style.borderWidth = 0;
  
        const th1 = document.createElement('th');
        th1.style.borderWidth = 0;
        const text1 = document.createTextNode(title);
  
        th1.appendChild(text1);
        tr.appendChild(th1);

        const th2 = document.createElement('th');
        th1.style.borderWidth = 0;
        th2.style.textAlign = 'right';
        const text2 = document.createTextNode([this.dataPoints[0].raw.bbch]);
  
        th2.appendChild(text2);
        tr.appendChild(th2);
        tableHead.appendChild(tr);
      });
  
      const tableBody = document.createElement('tbody');

      bodyLines.forEach((body, i) => {
        if ( i > 0 ) {
          return;
        }
        const colors = tooltip.labelColors[i];
  
        const span = document.createElement('span');
        span.style.background = colors.backgroundColor;
        span.style.borderColor = colors.borderColor;
        span.style.borderWidth = '2px';
        span.style.marginRight = '10px';
        span.style.height = '20px';
        span.style.width = '20px';
        span.style.display = 'inline-block';
  
        const tr = document.createElement('tr');
        tr.style.backgroundColor = 'inherit';
        tr.style.borderWidth = 0;

        const bodyparts = body[0].split(':');

        const td1 = document.createElement('td');
        td1.style.borderWidth = 0;
        const text1 = document.createTextNode([bodyparts[0] +':']);
        td1.appendChild(span);
        td1.appendChild(text1);
        tr.appendChild(td1);

        const td2 = document.createElement('td');
        td2.style.borderWidth = 0;
        const text2 = document.createTextNode([bodyparts[1]]);
        td2.appendChild(text2);
        tr.appendChild(td2);
        tableBody.appendChild(tr);
      });

      if ( this.dataPoints.length > 1 ) {
        var span = document.createElement('span');
        span.style.background = 'rgb(75,192,75)';
        span.style.borderColor = 'rgb(75,192,75)';
        span.style.borderWidth = '2px';
        span.style.marginRight = '10px';
        span.style.height = '20px';
        span.style.width = '20px';
        span.style.display = 'inline-block';

        var tr = document.createElement('tr');
        tr.style.backgroundColor = 'inherit';
        tr.style.borderWidth = 0;

        var td1 = document.createElement('td');
        td1.style.borderWidth = 0;
        var text1 = document.createTextNode(['Best:']);
        td1.appendChild(span);
        td1.appendChild(text1);
        tr.appendChild(td1);
        
        var td2 = document.createElement('td');
        td2.style.borderWidth = 0;
        var text2 = document.createTextNode([this.dataPoints[1].formattedValue +'-'+ this.dataPoints[2].formattedValue]);
        td2.appendChild(text2);
        tr.appendChild(td2);
        tableBody.appendChild(tr);
      }
  
      const tableRoot = tooltipEl.querySelector('table');
      tableRoot.style.whiteSpace = 'nowrap';
  
      // Remove old children
      while (tableRoot.firstChild) {
        tableRoot.firstChild.remove();
      }
  
      // Add new children
      tableRoot.appendChild(tableHead);
      tableRoot.appendChild(tableBody);
    }
    
    const {offsetLeft: positionX, offsetTop: positionY} = chart.canvas;
  
    // Display, position, and set styles for font
    tooltipEl.style.opacity = 1;
    tooltipEl.style.left = positionX + tooltip.caretX + 'px';
    tooltipEl.style.top = positionY + tooltip.dataPoints[0].element.y + 'px';
    tooltipEl.style.font = tooltip.options.bodyFont.string;
    tooltipEl.style.padding = tooltip.options.padding + 'px ' + tooltip.options.padding + 'px';
  },
  renderBBCHPoint: function(bbch) {
    ret = document.createElement('canvas');
    ret.id = 'bbch'+bbch;
    ret.width=32;
    ret.height=32;
    var pointctx = ret.getContext("2d");
    pointctx.roundRect(2, 2, 28, 28, 5);
    pointctx.lineWidth=2;
    pointctx.strokeStyle='#266868';
    pointctx.fillStyle='#266868';
    pointctx.fill();
    pointctx.stroke();
    pointctx.fillStyle='#ffffff';
    pointctx.font = "14px \"Helvetica Neue\", Helvetica, Arial, sans-serif";
    pointctx.textAlign='center';
    pointctx.textBaseline='middle'; 
    pointctx.fillText(bbch, 16, 16); 
    return ret;
  },
}
