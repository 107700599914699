
var site = {
  loading: false,
  langloaded: false,
  params: {},
  error: [],
  result: [],
  init: function() {
    site.params = site.getParams();
    site.bindLinks();
    site.loadLang();
    site.activateTab();
    //site.hideMessages();
    site.initPopovers();
    site.initTooltips();
    site.initToasts();
    site.ajaxLinks();
    measurements.init();
    site.scrollToFocus();
  },
  bindLinks: function() {
    $('.form-ajax').unbind().on('submit', function(e) {
      e.preventDefault();
      if ( $(this).hasClass('form-crypt') ) {
        site.cryptForm($(this)).then( result  => site.ajaxForm(result),
                                      error   => site.addError(error) );
      } else {
        site.ajaxForm($(this));
      }
    });
/*    if ( $('main').find('.infinite-body').length > 0 ) {
      $('.pagination').addClass('d-none');
      $(window).on('scroll', function () {
        if ( $('.pagination').find('.btn-next').length > 0 ) {
          if ($(document).height() - $(this).height() <= $(this).scrollTop()+100 ) {
            if ( site.isLoading() === false ) {
              site.isLoading(true);
              $('.pagination').addClass('d-none');
              $('.infinite-body').append('<div class="infinite-loadingani"><div></div><div></div><div></div><div></div></div>');
              $.ajax({
                url: $('.pagination .btn-next').attr('href'),
                method: 'GET',
                dataType: 'html',
                success: function(data) {
                  var data = $.parseHTML(data);
                  $('.infinite-body').append( $(data).find('.infinite-body').html() );
                  $('.pagination').replaceWith( $(data).find('.pagination') );
                  $('.infinite-loadingani').remove();
                  site.isLoading(false);
                  site.init();
                },
                error: function(e) {
                  $('.pagination').removeClass('d-none');
                  site.isLoading(false);
                  site.init();
                }
              });
            }
          }
        }
      });
    }*/
    if ( site.params.page[0] === 'login' ) {
      if ( typeof site.params.page[1] !== 'undefined' ) {
        if ( site.params.page[1] === 'register' ) {
          // page: login_register
        } else if ( site.params.page[1] === 'reset' ) {
          // page: login_reset
        }
      } else {
        // page: login
      }
    } else if ( site.params.page[0] === 'account' ) {
      if ( typeof site.params.page[1] !== 'undefined' ) {

      } else {
        // page: account
        $('.btn-uploadimg').on('click', function(e) {
          e.preventDefault();
          $('#uploadImg').trigger('click');
        });
        $('#uploadImg').on('change', function(e) {
          $('#upload-form').submit();
        });

      }
    } else if ( site.params.page[0] === 'projects' ) {
      $('.date-picker').datepicker({
        format: "yyyy-mm-dd",
        weekStart: 1,
        autoclose: true,
        todayHighlight: true
      });
    } else if ( site.params.page[0] === 'dashboard' ) {
      chartHelper.init();
      $('#measurementProject').unbind().on('change', function(e) {
        site.ajaxloadPage(['dashboard'],{'add': [$(this).val()]});
      });
      $('.input-daterange').datepicker({
        format: "yyyy-mm-dd",
        inputs: $('.input-daterange > input'),
        weekStart: 1,
        autoclose: true,
        endDate: '0d',
        startDate: '-10y',
        todayHighlight: true,
      });

      $('.btn-reset').unbind().on('click', function(e) {
        e.preventDefault();
        $('#measurement-form').removeClass('active');
        $('#measurement-form').find('input').val('');
        $('#measurement-form').find('select').val('');
        $('#measurement-form [selected="selected"]').removeAttr('selected');
        $('#chartProject').trigger('focus');
      });

      $('.chart-form').on('change', function(e) {
        if ( $('#chartProject option[selected="selected"]').attr('value') !== $('#chartProject').val() ) {
          site.ajaxloadPage(  [ 'dashboard' ], 
                              { 'show': [ $('#chartProject').val(), 
                                          $('[name="chartNutrition"]:checked').val(),
                                          luxon.DateTime.fromISO( $('#chartStartdate').val() ).toSeconds(), 
                                          luxon.DateTime.fromISO( $('#chartEnddate').val() ).toSeconds()
                                        ] } );
        } else {
          $(this).trigger('submit');
        }
      });
      $('.chart-form').on('submit', function(e) {
        e.preventDefault();
        measurements.getChartdata($('#chartProject').val(), luxon.DateTime.fromISO($('#chartStartdate').val()).toSeconds(), luxon.DateTime.fromISO($('#chartEnddate').val()+'T23:59:59').toSeconds(), $('[name="chartNutrition"]:checked').val());
      });
      $('.btn-prev').unbind().on('click', function(e) {
        e.preventDefault();
        var startDate=luxon.DateTime.fromISO($('#chartStartdate').val()).toSeconds();
        var endDate=luxon.DateTime.fromISO($('#chartEnddate').val()).toSeconds();
        var newStartdate=startDate-(endDate-startDate);
        var newEnddate=startDate;
        $('#chartStartdate').val(luxon.DateTime.fromSeconds(newStartdate).toISODate());
        $('#chartEnddate').val(luxon.DateTime.fromSeconds(newEnddate).toISODate());
        $('.btn-next').removeClass('disabled');
        $('.btn-next').attr( 'href', site.link(['dashboard'], {'show': [ $('#chartProject').val(), newStartdate, newEnddate, $('[name="chartNutrition"]:checked').val() ] } ) );
        measurements.getChartdata($('#chartProject').val(), newStartdate, newEnddate, $('[name="chartNutrition"]:checked').val());
      });
      $('.btn-next').unbind().on('click', function(e) {
        e.preventDefault();
        var startDate=luxon.DateTime.fromISO($('#chartStartdate').val()).toSeconds();
        var endDate=luxon.DateTime.fromISO($('#chartEnddate').val()).toSeconds();
        var newStartdate=endDate;
        var newEnddate=endDate+(endDate-startDate);
        $('#chartStartdate').val(luxon.DateTime.fromSeconds(newStartdate).toISODate());
        $('#chartEnddate').val(luxon.DateTime.fromSeconds(newEnddate).toISODate());
        $('.btn-prev').removeClass('disabled');
        $('.btn-prev').attr( 'href', site.link(['dashboard'], {'show': [ $('#chartProject').val(), newStartdate, newEnddate, $('[name="chartNutrition"]:checked').val() ] } ) );
        measurements.getChartdata($('#chartProject').val(), newStartdate, newEnddate, $('[name="chartNutrition"]:checked').val());
      });

      measurements.getChartdata($('#chartProject').val(), luxon.DateTime.fromISO($('#chartStartdate').val()+'T02:00:00').toSeconds(), luxon.DateTime.fromISO($('#chartEnddate').val()+'T23:59:59').toSeconds(), $('[name="chartNutrition"]:checked').val());
    } else if ( site.params.page[0] === 'admin' ) {
      if ( typeof site.params.page[1] !== 'undefined' ) {
        if ( site.params.page[1] === 'users' ) {

        } else if ( site.params.page[1] === 'projects' ) {
          $('#projects-filter').on('change', function(e) {
            $('#projects-filter').trigger('submit');
          });
          $('#projectFilterUser').on('focus', function(e) {
            $('#projectFilterUser').val('');
            $('#projectFilterUserId').val('-1');
          });
          $('#projectFilterUser').on('change', function(e) {
            if ( $('#filterlistUser option[value="'+$(this).val()+'"]').length > 0 ) {
              $('#projectFilterUserId').val( $('#filterlistUser option[value="'+$(this).val()+'"]').attr('data-id') );
            } else {
              $('#projectFilterUserId').val('');
            }
          });
        } else if ( site.params.page[1] === 'advices' ) {
          $('.btn-import').unbind().on('click', function(e) {
            e.preventDefault();
            $('#adviceImport').trigger('click');
          });
          $('#adviceImport').unbind().on('change', function(e) {
            $('#admin-advice-upload').trigger('submit');
          });

          $('#adminAdvices .btn-edit').unbind().on('click', function(e) {
            e.preventDefault();
            var j = false;
            var a = site.getParams( e.currentTarget.href.substring(e.currentTarget.origin.length) );
            var p = a.page;
            delete a.page;
            if ( e.currentTarget.href.indexOf('#') >= 0 ) {
              j = e.currentTarget.href.substring(e.currentTarget.href.indexOf('#'), e.currentTarget.href.length);
            }
            site.ajaxloadPage( p, a, admin.editAdvice, j );
          });

          $('#adviceCrop').unbind().on('change', function(e) {
            if ( $('#adviceCropOptions option[value="'+$(this).val()+'"]').length > 0 ) {
              $('#adviceGpo').val( $('#adviceCropOptions option[value="'+$(this).val()+'"]').attr('data-gpo') );
              $('#adviceGpo').attr('disabled', 'disabled');
              $('#adviceGpo').addClass('disabled');
            } else {
              $('#adviceGpo').val('');
              $('#adviceGpo').removeAttr('disabled');
              $('#adviceGpo').removeClass('disabled');
            }
          });
          $('.admin-advice-form .btn-reset').unbind().on('click', function(e) {
            e.preventDefault();
            site.ajaxloadPage(['admin','advices']);
          });
          $('.toast.dialog .btn-yes').unbind().on('click', function(e) {
            e.preventDefault();
            var args = site.getParams($(this).attr('href'));
            var page = args['page'];
            delete args['page'];
            $('.toast-container .toast.dialog').fadeOut( 300, function() {
              $('.toast-container .toast.dialog').dispose();
              site.ajaxloadPage(page, args);
            });
          });
          $('.toast.dialog .btn-no').unbind().on('click', function(e) {
            e.preventDefault();
            $('.toast-container .toast.dialog').fadeOut( 300, function() {
              $('.toast-container .toast.dialog').dispose();
            });
          });
        } else if ( site.params.page[1] === 'crops' ) {
          $('.btn-import').unbind().on('click', function(e) {
            e.preventDefault();
            $('#cropImport').trigger('click');
          });
          $('#cropImport').unbind().on('change', function(e) {
            $('#admin-crop-upload').trigger('submit');
          });
        } else if ( site.params.page[1] === 'downloads' ) {
          $('#filterCategory').unbind().on('change', function(e) {
            $('#admin-downloads-filter-form').trigger('submit');
          });
        }
      } else {
          // page: admin
      }
    }
  },
  loadLang: function() {
    if ( site.langloaded === false ) {
      site.langloaded = true;
      $.getJSON( basepath +"locale/"+ language +"/nutrilab.json", function( data ) {
        if ( typeof data == 'object' ) {
          gt.loadJSON( data, 'messages' );
        } else {
          $.getJSON( basepath +"locale/nutrilab.json", function( data ) {
            gt.loadJSON( data, 'messages' );
          });
        }
      }).fail( function() {
        $.getJSON( basepath +"locale/nutrilab.json", function( data ) {
          gt.loadJSON( data, 'messages' );
        });
      });
    }
  },
  reload: function(p,a) {
    setTimeout( function() {
      document.location.href = site.link(p,a);
    }, 1000 );
  },
  activateTab: function() {
    var url = window.location.href;
    if ( url.indexOf('#') >= 0 ) {
      
      var activeTab = url.substring(url.indexOf("#") + 1);
      if ( $('[href="#'+activeTab+'"]').length ) {
        var triggerElement = $('[href="#'+activeTab+'"]');
        var tab = new bootstrap.Tab(triggerElement);
        tab.show();
      }
    }
  },
  validateEmail: function(input) {
    return String(input)
      .toLowerCase()
      .match( /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ );
  },
  cryptForm: function(form) {
    return new Promise(function(resolve, reject) {
      var pw = '';
      $(form).find('[type="password"]').each( function(e) {
        if ( pw === '' ) {
          pw = $(this).val();
        }
        if ( pw !== '' && $(this).val() != pw ) {
          reject(_('Password missmatch'));
        }
      });
      if ( pw != '' ) {
        form.find('[name="encryptedPw"]').val( CryptoJS.SHA256( pw ).toString( CryptoJS.enc.Hex ) );
        form.find('[type="password"]').val('').removeAttr('required');
      }
      resolve(form);
    });
  },
  validateForm: function( form ) {
    var ret = true;
    $.each( form.find('[data-validate-min]'), function(k, v) {
      if ( $(v).val() < $(v).attr('data-validate-min') ) {
        ret = false;
        $(v).addClass('is-invalid');
      }
    });
    $.each( form.find('[data-validate-max]'), function(k, v) {
      if ( $(v).val() > $(v).attr('data-validate-max') ) {
        ret = false;
        $(v).addClass('is-invalid');
      }
    });
    $.each( form.find('[required]'), function(k, v) {
      if ( $(v).val() == '' ) {
        ret = false;
        $(v).addClass('is-invalid');
      }
    });
    $.each( form.find('[type="email"]'), function(k, v) {
      if ( site.validateEmail($(v).val()) === null ) {
        ret = false;
        $(v).addClass('is-invalid');
      }
    });
    return ret;
  },
  ajaxForm: function( form ) {
    if ( this.validateForm( form ) !== true ) {
      form.find('[type="password"]').attr('required', 'required');
      form.find('[name="encryptedPw"]').val('');
    } else {
      if ( this.isLoading() === false ) {
        this.isLoading(true);
        site.toggleForm(form, false);
        var formaction = form.attr('action');
        var formdata = form.serialize();
        var args = site.getParams(formaction);
        var page = args['page'];
        delete args['page'];
        $.ajax({
          url: formaction,
          method: 'POST',
          dataType: 'html',
          data: formdata,
          success: function(data) {
            if ( $($($.parseHTML(data)).filter('meta[http-equiv="refresh"]')).length <= 0 ) {
              $('#sidebarMenu').replaceWith( $($($.parseHTML(data)).filter('#sidebarMenu')) );
              $('main').replaceWith( $($.parseHTML(data)).find('main') );
            }
            
            $('.toast-container').replaceWith( $($.parseHTML(data)).find('.toast-container') );
            $('head').append($($($.parseHTML(data)).filter('meta[http-equiv="refresh"]')));
            
            site.page = page;
            site.args = args;
            site.isLoading(false);
            site.init();
          },
          error: function(data) {
            site.addError( data );
            site.toggleForm(form);
            site.isLoading(false);
            return false;
          }
        }).done(function() {
          form.find('[name="encryptedPw"]').val('');
          site.isLoading(false);
          return true;
        });
      }
    }
    return false;
  },
  toggleForm: function(form, toggle) {
    // true = on
    if ( typeof toggle === 'undefined' ) {
      if ( form.hasClass('disabled') ) {
        var toggle=true;
      } else {
        var toggle=false;
      }
    }
    if ( toggle === true ) {
      form.removeClass( "disabled" );
      form.find('button').removeAttr('disabled');
      form.find('input[type="submit"]').removeAttr('disabled');
      form.find('input[type="reset"]').removeAttr('disabled');
    } else {
      form.addClass( "disabled" );
      form.find('button').attr('disabled', 'disabled');
      form.find('input[type="submit"]').attr('disabled', 'disabled');
      form.find('input[type="reset"]').attr('disabled', 'disabled');
    }
  },
  addError: function( input ) {

    var html =  "<div class=\"toast bg-danger\" role=\"alert\" aria-live=\"assertive\" aria-atomic=\"true\" data-delay=\"15000\">\n"+
                "  <div class=\"toast-header\">\n"+
                "    <strong class=\"me-auto\">"+ _('Error') +"</strong>\n"+
                "    <button type=\"button\" class=\"btn-close\" data-bs-dismiss=\"toast\" aria-label=\"Close\"></button>\n"+
                "  </div>\n"+
                "  <div class=\"toast-body\">\n"+
                input +
                "  </div>\n"+
                "</div>\n";

    /*var html =  '<p class="msg error alert bg-danger text-white">'+
                '<button type="button" class="btn-close btn-sm float-end" aria-label="Close"></button>'+
                input +'</p>';*/
    $('.toast-container').append(html);
    site.initToasts();
  },
  addResult: function( input ) {
    var html =  "<div class=\"toast\" role=\"status\" aria-live=\"polite\" aria-atomic=\"true\">\n"+
                "  <div class=\"toast-header\">\n"+
                "    <strong class=\"me-auto\">"+ _('Result') +"</strong>\n"+
                "    <button type=\"button\" class=\"btn-close\" data-bs-dismiss=\"toast\" aria-label=\"Close\"></button>\n"+
                "  </div>\n"+
                "  <div class=\"toast-body\">\n"+
                input +
                "  </div>\n"+
                "</div>\n";
    $('.toast-container').append(html);
    //site.hideMessages();
    site.initToasts();
  },
  initPopovers: function() {
    var pop = $('[data-bs-toggle="popover"]').popover();
    pop.on('shown.bs.popover', function(){
      $('.popover .btn-no').unbind().on('click', function(e) {
        e.preventDefault();
        pop.popover('hide');
      });
      $('.popover .btn-yes').unbind().on('click', function(e) {
        e.preventDefault();
        var args = site.getParams($(this).attr('href'));
        var page = args['page'];
        delete args['page'];
        pop.popover('hide');
        site.ajaxloadPage(page, args/*, function(data) {
          site.page = page;
          site.args = args;
          $('.toast-container').replaceWith( $($.parseHTML(data)).find('.toast-container') );
          site.isLoading(false);
          site.init();
          $('tr.active').fadeOut( 300, 'linear', function() { $(this).remove(); });
          if ( $('div.row.active div.row.active').length > 0 ) {
            $('div.row.active div.row.active').fadeOut( 300, 'linear', function() { $(this).remove(); });
          } else {
            $('div.row.active').fadeOut( 300, 'linear', function() { $(this).remove(); });
          }
        }*/);
      });
    }).on('hidden.bs.popover', function(e) {
      $('tr.active').removeClass('active');
      $(this).parents('div.row').first().parent('div').find('.active').removeClass('active');
    }).on('click', function(e) {
      e.preventDefault();
      $('tr.active').removeClass('active');
      $(this).parents('div.row').first().parent('div').find('.active').removeClass('active');
      $(this).parents('tr').addClass('active');
      $(this).parents('div.row').first().addClass('active');
    });
    var persistentpop = $('[data-bs-toggle="popover-persistent"]').popover();
    persistentpop.popover({
      trigger: "manual",
      html: true,
      animation: false
    })
    .on("mouseenter", function() {
      var _this = this;
      $(this).popover("show");
      $('.popover').on("mouseleave", function() {
        $(_this).popover('hide');
      });
    }).on("mouseleave", function() {
      var _this = this;
      setTimeout(function() {
        if (!$('.popover:hover').length) {
          $(_this).popover("hide");
        }
      }, 300);
    });
  },
  initTooltips: function() {
    $('[data-bs-toggle="tooltip"]').tooltip();
  },
  initToasts: function() {
    $('.toast').each( function( i, e ) {
      $(e).toast({delay: $(e).attr('data-delay') ? parseInt($(e).attr('data-delay')) : 1500 });
    });
    $('.toast').toast('show');
    $('.toast').on('hidden.bs.toast', function( i, e ) {
      $(this).remove();
    });
  },
  getParams: function( url ) {
    var ret = [];
    if ( typeof url === 'undefined' ) {
      var url = window.location.pathname;
    }
    url = url.replace(basepath, '');
    var components = decodeURIComponent( url ).split('/');
    components.forEach( function (c) {
      var p = c.split(',');
      var k = p.shift();
      if ( p.length > 0 ) {
        ret[k] = p;
      } else {
        ret[k] = true;
      }
    });
    if ( typeof ret.page === 'undefined' ) {
      ret.page = ['home'];
    }
    return ret;
  },
  hideMessages: function() {
    setTimeout( function() {
      $('.messagebox .msg.result').fadeOut( 300, function() {
        $('.messagebox .msg.result').remove();
      });
    }, 5000 );
    setTimeout( function() {
      $('.messagebox .msg.error').fadeOut( 300, function() {
        $('.messagebox .msg.error').remove();
      });
    }, 60000 );
  },
  link: function( path, args ) {
    a = "";
    if ( typeof args === 'object' ) {
      a = "/";
      for (const [k, v] of Object.entries(args)) {
        if ( typeof v === 'object' ) {
          a += k +','+ v.join(',') +'/';
        } else {
          a += k +'/';
        }
      }
      a = a.substr( 0, a.length-1 );
    }
    return basepath +'page,'+ path.join(',') +a;
  },
  ajaxLinks: function() {
    $('.ajax-link').unbind().on('click', function(e) {
      e.preventDefault();
      var j = false;
      var a = site.getParams( e.currentTarget.href.substring(e.currentTarget.origin.length) );
      var p = a.page;
      delete a.page;
      if ( e.currentTarget.href.indexOf('#') >= 0 ) {
        j = e.currentTarget.href.substring(e.currentTarget.href.indexOf('#'), e.currentTarget.href.length);
      }
      site.ajaxloadPage( p, a, false, j );
    });
  },
  isLoading: function(toggle) {
    if ( typeof toggle === 'undefined' ) {
      return this.loading;
    } else {
      if ( toggle === true ) {
        $("html").css("cursor", "wait");
      } else {
        $("html").css("cursor", "default");
      }
      this.loading=toggle;
    }
  },
  scrollToFocus: function() {
    if ( $('.focus').length > 0 ) {
      $('.focus').trigger('focus');
      var pos = parseInt( $('.focus').offset().top-100 );
      if ( pos < 0 ) {
        pos = 0;
      }
      if ( $([document.documentElement, document.body]).scrollTop() != pos ) {
        $([document.documentElement, document.body]).animate({
          scrollTop: pos
        }, 
        10,
        function() {
          $([document.documentElement, document.body]).css( "scrollTop", pos );
        });
      }
    }
  },
  ajaxloadPage: function( page, args, callback, jumpto ) {
    if ( this.isLoading() !== true ) {
      this.isLoading(true);
      if ( typeof page === 'undefined' ) {
        page = site.params['page'];
      }
      if ( typeof callback === 'undefined' ) {
        callback = false;
      }
      if ( typeof jumpto === 'undefined' ) {
        jumpto = false;
      }
      var ajaxurl = site.link( page, args );
      $.ajax({
        url: ajaxurl,
        method: 'GET',
        dataType: 'html',
        success: function(data) {
          if ( callback === false ) { 
            $('main').replaceWith( $($.parseHTML(data)).find('main') );
            $('#sidebarMenu').replaceWith( $($($.parseHTML(data)).filter('#sidebarMenu')) );
            site.page = page;
            site.args = args;
            $('.toast-container').replaceWith( $($.parseHTML(data)).find('.toast-container') );
            site.isLoading(false);
            site.init();
            if ( jumpto !== false ) {
              var pos = parseInt( $(jumpto).offset().top-80 );
              if ( pos < 0 ) {
                pos = 0;
              }
              if ( $([document.documentElement, document.body]).scrollTop() != pos ) {
                $([document.documentElement, document.body]).animate({
                  scrollTop: pos
                }, 
                10,
                function() {
                  $([document.documentElement, document.body]).css( "scrollTop", pos );
                });
              }
            }
          } else {
            site.isLoading(false);
            callback(data);
          }
        },
        error: function() {
          site.isLoading(false);
        },
        done: function() {
          site.isLoading(false);
        }
      });
    }
  }
};
 