var admin = {
  init: function() {
  },
  editAdvice: function(data) {
    $( '#advice'+ $( $.parseHTML(data) ).find('#admin-advice-form').find('#adviceId').val() ).replaceWith( $( $.parseHTML(data)).find('#admin-advice-form' ) );

    $('.toast-container').replaceWith( $($.parseHTML(data)).find('.toast-container') );
    site.isLoading(false);
    site.init();
  }
}
